<template>
  <div class="comments-box">
    <img class="head-img" :src="detail.picture" alt="" />
    <div class="content">
      <div class="name">{{ detail.nickname }}</div>
      <div class="text">{{ detail.content }}</div>
      <div class="info">
        <span>{{ detail.createTime }}</span>
        <a @click="confirmDel">{{ $t('8018b9c') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    delComments: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    confirmDel() {
      this.$confirm('确定删除此评论？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delHandle()
      })
    },
    delHandle() {
      if (this.delComments) {
        this.delComments(this.detail.id)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.head {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.head-img {
  width: 40px;
  height: 40px;
  background-color: #ccc;
  margin-right: 10px;
}
.comments-box {
  display: flex;
}
.content {
  color: #666666;
}
.text {
  line-height: 21px;
  margin-bottom: 20px;
}
.name {
  color: #333333;
  font-weight: bold;
  margin-bottom: 12px;
}
.info {
  font-size: 12px;
  color: #999;
  span {
    margin-right: 10px;
  }
  a {
    color: #999;
  }
}
</style>
