<template>
  <div class="container">
    <Box v-loading="loading" :title="autoPrefix('d302280')" style="margin-bottom: 0">
      <Empty v-if="!(list && list.length) && !loading" type="serviceContent" />
      <div v-for="(article, idx) in list" :key="idx" class="item-box">
        <div class="row">
          <div class="label">{{ $t('0ea635c') }}：</div>
          <div>{{ article.title }}</div>
        </div>
        <div class="row">
          <div class="label">{{ $t('dcce879') }}：</div>
          <div v-if="article.content" class="content">
            <div class="text" v-html="article.content"></div>
            <a class="link" @click="viewDetail(article.id)">{{ $t('ad6ab8a') }}&gt;&gt;</a>
          </div>
        </div>
        <hr class="line" />
        <div
          v-for="(comment, comIdx) in article.comments"
          :key="comIdx"
        >
          <Comments
            :key="comIdx"
            :detail="comment"
            :del-comments="delComments"
          />
          <hr v-if="comIdx !== article.comments.length - 1" class="comment-line" />
        </div>

      </div>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/Box'
import Api from '@/api/userCenter'
import Comments from './Comments'
import Empty from "@/components/Empty/Factory"
export default {
  components: {
    Box,
    Comments,
    Empty
  },
  data() {
    return {
      list: [],
      loading: false
    }
  },
  mounted() {
    this.getMybbs()
  },
  methods: {
    autoPrefix(name) {
      let fun = '$t'
      if (this.abroad) {
        fun = 'geEn'
      }
      return this[fun](name)
    },
    getMybbs() {
      this.loading = true
      Api.getMyBBS()
        .then((resp) => {
          this.list = resp.content
          this.loading = false
        })
        .catch(() => {
          this.list = []
          this.loading = false
        })
    },
    delComments(commentId) {
      Api.delComments([commentId])
        .then(() => {
          this.$message.success('操作成功！')
          this.getMybbs()
        })
    },
    viewDetail(articleId) {
      this.$router.push(`/communication/forum/detail?id=${articleId}`)
    }
  },
}
</script>

<style lang="less" scoped>
.item-box {
  border: 1px solid #EEEEEE;
  padding: 30px;
  margin-bottom: 30px;
}
.label {
  color: #999999;
  flex-shrink: 0;
}
.row {
  color: #333333;
  margin-bottom: 24px;
  display: flex;
  line-height: 24px;
  .content {
    flex-grow: 1;
    .text {
      width: 100%;
      height: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.link {
  color: #00A4FF;
}
.line {
  border-top: 1px dashed rgba(0, 0, 0, 0.09);
  margin-bottom: 30px;
}
.comment-line {
  margin: 30px 0;
  border-top: 1px solid #EEEEEE;
}
</style>
